.markdownImage {
  /**
   * Note: markdown images might be too small for filling the space.
   */
  width: auto;
  max-width: 100%;
  border-radius: 0px;
  object-fit: cover;
}

.fieldImage {
  width: 100%;
    /* height: 200px; */
    border-radius: 5px;
    object-fit: cover;
    /* aspect-ratio: 1; */
}
