.layout {
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
}

.topbar {
  position: sticky;
  top: -2px;
  z-index: var(--zIndexTopbar);
}

.main {
  display: grid;
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.topdiv {
  /* min-height: 122px; */
  display: flex;
  align-items: center;
  padding: 20px 25px 30px;
  border-bottom: 2px solid #000000;
  /* background: #ffffff; */
  & > div:nth-child(1) {
    flex-basis: 55%;
    & > span {
      font-size: 24px;
      font-weight: 500;
      color: #000000;
      line-height: 30px;
      font-family: 'NeueHaasGroteskText Pro';
      /* font-weight: bold; */
      /* font-family:'NeueHaasGroteskDisp Pro Md'; */
      display: block;
      /* @media (max-width: 1199px) {
        font-size: 42px;
        line-height: 52px;
      } */
      max-width: 590px;
      @media (max-width: 1199px) {
        font-size: 20px;
      }
      @media (max-width: 991px) {
        font-size: 23px;
        line-height: 35px;
      }
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 26px;
      }
      @media (max-width: 767px) {
        font-size: 15px;
        line-height: 26px;
      }
      @media (max-width: 575px) {
        /* font-size: 32px; */
        /* line-height: 45px; */
        /* display: inline; */
        /* margin-left: 5px; */
      }
      /* @media(max-width:374px){
        font-size: 26px;
        line-height: 35px;
      } */
    }
    @media (max-width: 767px) {
      flex-basis: 100%;
      padding: 0 15px;
    }
  }
  @media (max-width: 991px) {
    flex-direction: column;
    text-align: center;
    padding: 25px 72px 40px;
  }
  @media (max-width: 575px) {
    padding: 40px 0 40px;
  }
}
.searchDiv {
  /* margin-left: auto; */

  /* border: 1px solid #000000; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  max-height: 55px;
  width: auto;
  margin-right: 90px;
  margin-left: auto;
  /* padding: 0 2px 0 20px;
  & > svg {
    height: 32px;
    width: 32px;
    @media (max-width: 768px) {
      height: 16px;
    }
  } */
  /* & > input {
    border: none;
    width: auto;
    &:focus {
      box-shadow: none;
    }
  } */
  @media (max-width: 991px) {
    margin: 20px auto 0;
  }
  @media (max-width: 767px) {
    margin-top: 32px;
    padding: 0;
    width: 100%;
    justify-content: center;
  }
}
.srchBtn {
  border: none;
  background: #000;
  color: #fff;
  border-radius: 0;
  min-width: 300px;
  min-height: 55px;
  font-size: 16px;
  font-family: 'NeueHaasGroteskText Pro';
  font-weight: bold;
  cursor: pointer;
  /* @media (max-width: 768px) {
    display: none;
  } */
  @media (max-width: 767px) {
    min-width: 270px;
  }
}
.topdivContent {
  & > h1 {
    text-transform: capitalize;
    font-size: 40px;
    line-height: 50px;
    color: #000;
    font-weight: 500;
    margin-bottom: 10px;
    @media (max-width: 767px) {
      font-size: 35px;
      line-height: 45px;
    }
    @media (max-width: 575px) {
      font-size: 25px;
      line-height: 32px;
      margin-bottom: 5px;
    }
  }
  & > span {
    /* display: inline !important; */
    display: block !important;
    @media (max-width: 575px) {
    }
  }
}
.HerobnnrImg {
  /* background-image:url(../../assets/Banner-img-new.png); */
  /* background-image:url(../../assets/Hero-banner-image-two.png); */
  background-image: url(../../assets/adventurely-fall-2024.jpg);
  height: 110vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top -230px center;
  @media (min-width: 1920px) {
    background-position: top -230px center;
  }
  @media (min-width: 1400px) {
    background-position: top -210px center;
  }
  @media (min-width: 1200px) {
    background-position: top -160px center;
  }
  @media (max-width: 1199px) {
    background-position: top center;
  }
  @media (max-width: 991px) {
    height: 65vh;
    background-position: 80%;
  }
  @media (max-width: 575px) {
    /* background-image:url(../../assets/mob-img-new.png); */
    height: 50vh;
    background-position: 30%;
  }
  /* @media(min-width:1440px){
    height:600px;
  }
  @media(min-width:1920px){
    height:800px;
  }
  @media(min-width:2500px){
    height:1000px;
  } */
}
