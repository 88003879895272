.root {
}

.media {
  /* width: 100%; */
  border-radius: 45px;
  overflow: hidden;
  margin-bottom: 0;
  & > div {
    & > div {
      padding-bottom: 58.25% !important;
      & > div {
        & > img {
          height: 200px;
          /* width: 370px; */
          object-position: top center;
          @media (max-width: 1199px) {
            height: auto;
          }
          /* @media (max-width: 390px) {
            height: 130px;
          } */
        }
      }
      @media (max-width: 1199px) {
        padding-bottom: 56.25% !important;
      }
      /* @media (max-width: 390px) {
        padding-bottom: 90.25% !important;
      } */
    }
  }
}

.text {
  width: 100%;
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }
  & > h1 {
    @media (max-width: 991px) {
      font-size: 31px;
    }
  }
  & > h3 {
    @media (max-width: 991px) {
      font-size: 22px;
    }
  }
  /* @media (max-width: 1199px) {
    margin-top: 25px;
  } */
  & > h3 {
    font-family: 'NeueHaasGroteskText Pro Md';
    font-weight: 500;
    @media (max-width: 1199px) {
      padding: 0;
    }
  }
  & > p {
    font-family: 'NeueHaasGroteskText Pro';
    font-weight: 400;
    font-size: 16px;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
}

.customBlockLink {
  &:hover {
    cursor: pointer;
  }
}

.funadventure{
display: block;
}
.featureSection{
  
}