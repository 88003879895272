.backgroundImageWrapper {
  /* position: absolute; */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.backgroundImage {
  object-fit: cover;
  width: 100%;
  /* height: 780px; */
  object-position: top;
  @media (min-width: 1600px) {
    /* height: 860px; */
  }
  @media (min-width: 1920px) {
    height: auto;
  }
}
