@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  &:nth-of-type(odd) {
    /* background-color: var(--colorWhite); */
    background-color: #fffbf2;
  }
  &:nth-child(2) {

    & > div {
      & > img {
        @media (max-width: 575px) {
          height: 200px;
          object-fit:inherit !important;
        }
      }
    }
  }
  &:nth-child(3) {
    @media(max-width:991px){
      padding:0 0 60px;
    }
    & > div:nth-child(2) {
      & > div:nth-child(2) {
        @media (max-width: 767px) {
          grid-template-columns: repeat(2, 1fr);
        }
        /* @media (max-width: 390px) {
          grid-template-columns: auto auto;
        } */
        & p {
          text-align: left;
          font-family: 'NeueHaasGroteskText Pro Md';
          font-weight: 500;
          @media (max-width: 1199px) {
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }
  &:nth-child(4) {
    & > div:nth-child(2) {
      & > div:nth-child(2) {
        padding-bottom: 60px;
        @media (min-width: 575px) and (max-width: 768px) {
          grid-template-columns: repeat(2, 1fr);
        }
        & > div {
          & > div:nth-child(2) {
            & > p {
              text-align: left !important;
              font-family: 'NeueHaasGroteskText Pro Md';
              font-weight: 500;
              padding: 0;
              margin: 0;
              @media (max-width: 1199px) {
                padding: 0;
              }
            }
          }
        }
        @media (max-width: 991px) {
          padding-bottom: 35px;
        }
      }
    }
  }
  &:nth-child(5) {
    background-color: #fffbf2;
    & > div {
      & > header {
        padding-top: 0;
        padding-bottom: 100px;
        & > a {
          /* background-color: #ffffff; */
          background-color: transparent;

          border: 1px solid #000000;
          color: #000000;
          box-shadow: none;
          font-size: 14px;
          margin-top: 0;
          font-family: 'NeueHaasGroteskText Pro Md';
          font-weight: 500;
          &:hover {
            color: #000000 !important;
          }
          @media (max-width: 768px) {
            font-size: 12px;
          }
        }
        @media (max-width: 991px) {
          padding-bottom: 70px;
        }
        @media (max-width: 767px) {
          padding-bottom: 45px;
        }
      }
    }
    @media (max-width: 768px) {
      text-align: center;
    }
  }
  &:nth-child(6) {
    /* background-color: #ffffff; */
  }

  &:nth-child(7) {
    padding: 100px 0;
    & > div {
      & > div {
        display: flex;
        & > div {
          flex-basis: 50%;
          @media (max-width: 991px) {
            flex-basis: 100%;
          }
         & > div{
          & > div{
            border-radius:0 !important;
            overflow: unset !important;
            & > div{
              & > div{
                @media(max-width:991px){
                  padding-bottom: 71.25% !important;
                }
                & > div{
                  & > img{
                    height: 370px;
                    /* height:fit-content; */
                    object-fit: cover;
                    
                    @media(max-width:991px){
                      height:100%;
                      object-position:inherit !important;
                    }
                                        

                  }
                }
              }
            }
          }
         }
        }
        & > div:nth-child(1) {
          & > div {
            width: 100%;
          }
          & img {
            @media (max-width: 768px) {
              /* border-radius: 10px; */
            }
          }
        }
        & > div:nth-child(2) {
          align-items: flex-start;
          & > div {
            & > h3 {
              font-weight: 500;
              word-break: auto-phrase !important;
            }
          }
          @media (max-width: 768px) {
            padding: 0 16px;
          }
        }
        @media (max-width: 1199px) {
          padding-bottom: 100px;
        }
        @media (max-width: 991px) {
          flex-direction: column;
          padding: 0;
        }
      }
    }
    @media (max-width: 1199px) {
      padding: 30px 0;
    }
    @media (max-width: 991px) {
      padding: 60px 0;
    }
    /* & > div:nth-child(2){
      & > div{
        & > div{
          @media(max-width:575px){
            margin-top:50px;
          }
        }
      }
    } */
  }
}
/* #funadventure{
  padding: 200px 0;
} */

.sectionContent {
  /* padding: 32px 0; */
  position: relative;
  /* padding: 150px 0; */
  @media (--viewportMedium) {
    /* padding: 64px 0; */
    /* padding: 100px 0; */
  }
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
