@import '../../../../styles/customMediaQueries.css';

.baseColumn {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 32px;
}

.featuresMain {
  composes: baseColumn;
  & > div:nth-child(1){
    & > div{
      & > div{
        & > div{
          @media(max-width:991px){
           padding-bottom:75% !important;
          }
          & > div{
            & > img{
              height:320px !important;
              @media(max-width:1199px){
                height:auto !important;
              }
            }
          }
        }
      }
    }
  }
  & > div:nth-child(2){
    & > div{
      & > h3{
        font-size:30px;
        @media(max-width:767px){
          font-size: 25px;
        }
      }
    }
  }
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-auto-flow: dense;
  gap: 0;

  @media (--viewportMedium) {
    gap: 64px;
    flex-direction: row-reverse;
  }

  &:nth-child(even) {
    @media (--viewportMedium) {
      flex-direction: row;
    }
  }
  @media(max-width:991px){
    align-items:flex-start;
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}
