.video {
  position: relative;
  overflow: hidden;
  border-radius: 0px;
}
.iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
